<template>
  <el-dialog
    :visible.sync="showUsernameModal"
    :show-close="false"
    :close-on-click-modal="false"
    style="padding: 0; text-align: left"
    class="dialogue"
  >
    <div class="dialogue__heading">
      <h2>Enter new username</h2>
      <div class="dialogue__close" @click="$emit('close')">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.3 10.0006L19.4873 3.81337C20.1709 3.12974 20.1709 2.02141 19.4873 1.33885L18.6624 0.514004C17.9786 -0.169839 16.8703 -0.169839 16.1877 0.514004L10.0006 6.70106L3.81337 0.512722C3.12974 -0.170907 2.02141 -0.170907 1.33885 0.512722L0.512722 1.33756C-0.170907 2.02141 -0.170907 3.12974 0.512722 3.8123L6.70106 10.0006L0.514004 16.1877C-0.169839 16.8715 -0.169839 17.9799 0.514004 18.6624L1.33885 19.4873C2.02247 20.1709 3.13081 20.1709 3.81337 19.4873L10.0006 13.3L16.1877 19.4873C16.8715 20.1709 17.9799 20.1709 18.6624 19.4873L19.4873 18.6624C20.1709 17.9786 20.1709 16.8703 19.4873 16.1877L13.3 10.0006Z"
            fill="#DB353A"
          />
        </svg>
      </div>
    </div>

    <form class="form" @submit.prevent="submitForm" novalidate="true">
      <div class="form__group">
        <label for="username">
          <input
            type="text"
            id="new_username"
            name="new_username"
            placeholder="Choose a new username"
            class="form__input"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            v-model.lazy="$v.form.new_username.$model"
            :class="{
              'is-invalid': submitted && $v.form.new_username.$error,
            }"
        /></label>
        <small class="error" v-if="submitted && !$v.form.new_username.required"
          >Please, choose a new username!</small
        >
      </div>
      <button type="submit" class="btn btn__red btn__group">
        <loading v-if="loading" />
        <span>Submit</span>
      </button>
    </form>
  </el-dialog>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import api from "@/api/api.js";
export default {
  data() {
    return {
      submitted: false,
      loading: false,
      form: {
        new_username: "",
      },
    };
  },
  validations: {
    form: {
      new_username: { required },
    },
  },
  props: ["showUsernameModal"],
  methods: {
    submitForm() {
      this.submitted = true;
      this.$v.form.$touch();
      if (this.$v.form.new_username.$invalid) {
        return;
      }
      let payload = this.$store.state.onePayNewUsernameData;
      delete payload.otp;
      payload.new_username = this.form.new_username;
      this.loading = true;
      api
        .onePayUserWithNewUsername(payload)
        .then((response) => {
          this.loading = false;
          let responseCode = response.Code;
          let responseStatus = response.Status;
          if (responseStatus && responseCode == "CUSCREATE002") {
            this.$message({
              showClose: true,
              message: `User onboarded successfully, proceed to login`,
              type: "success",
              duration: 10000,
            });
            this.$router.push("/login");
          } else {
            return;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `OTP generation error`,
            type: "error",
            duration: 10000,
          });
          throw error;
        });
    },
    handleOnComplete(value) {
      if (value !== "") {
        this.$emit("userModal", value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
</style>
