var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingTwo),expression:"loadingTwo"}],staticClass:"container"},[_c('a',{staticClass:"btn__back",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('svg',{attrs:{"width":"20","height":"20","viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M10 20C15.5141 20 20 15.5141 20 10C20 4.48586 15.5141 0 10 0C4.48586 0 0 4.48586 0 10C0 15.5141 4.48586 20 10 20ZM6.21121 9.68795L11.4263 4.47283C11.5115 4.38763 11.6228 4.34591 11.734 4.34591C11.8453 4.34591 11.9565 4.38849 12.0417 4.47283C12.2112 4.64232 12.2112 4.91786 12.0417 5.08735L7.13429 9.99479L12.0409 14.9031C12.2104 15.0726 12.2104 15.3481 12.0409 15.5176C11.8714 15.6871 11.5958 15.6871 11.4263 15.5176L6.21121 10.3025C6.04172 10.133 6.04172 9.85744 6.21121 9.68795Z","fill":"#DB353A"}})]),_c('span',[_vm._v("Back")])]),_vm._m(0),_c('form',{staticClass:"form",attrs:{"novalidate":"true"},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('div',{staticClass:"form__group"},[_c('label',{attrs:{"for":"username"}},[_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.$v.form.username.$model),expression:"$v.form.username.$model",modifiers:{"lazy":true}}],staticClass:"form__input",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.username.$error,
          },attrs:{"type":"text","id":"username","name":"username","placeholder":"OnePay Username","autocorrect":"off","spellcheck":"false","autocomplete":"off"},domProps:{"value":(_vm.$v.form.username.$model)},on:{"change":function($event){return _vm.$set(_vm.$v.form.username, "$model", $event.target.value)}}})]),(_vm.submitted && !_vm.$v.form.username.required)?_c('small',{staticClass:"error"},[_vm._v("Please, enter your OnePay username!")]):_vm._e()]),_c('div',{staticClass:"form__group"},[_c('label',{attrs:{"for":"password"}},[_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.$v.form.password.$model),expression:"$v.form.password.$model",modifiers:{"lazy":true}}],staticClass:"form__input",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.password.$error,
          },attrs:{"type":"password","id":"password","name":"password","placeholder":"OnePay Password","autocorrect":"off","spellcheck":"false","autocomplete":"off"},domProps:{"value":(_vm.$v.form.password.$model)},on:{"change":function($event){return _vm.$set(_vm.$v.form.password, "$model", $event.target.value)}}})]),(_vm.submitted && !_vm.$v.form.password.required)?_c('small',{staticClass:"error"},[_vm._v("Please enter your OnePay password")]):_vm._e()]),_c('div',{staticClass:"form__group"},[_c('label',{attrs:{"for":"transactionpin"}},[((_vm.passwordFieldTypeThree)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.transactionpin.$model),expression:"$v.form.transactionpin.$model"}],staticClass:"form__input",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.transactionpin.$error,
          },attrs:{"id":"transactionpin","name":"transactionpin","placeholder":"Choose OneBank Transaction Pin","autocorrect":"off","spellcheck":"false","autocomplete":"off","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.$v.form.transactionpin.$model)?_vm._i(_vm.$v.form.transactionpin.$model,null)>-1:(_vm.$v.form.transactionpin.$model)},on:{"change":function($event){var $$a=_vm.$v.form.transactionpin.$model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.$v.form.transactionpin, "$model", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.$v.form.transactionpin, "$model", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.$v.form.transactionpin, "$model", $$c)}}}}):((_vm.passwordFieldTypeThree)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.transactionpin.$model),expression:"$v.form.transactionpin.$model"}],staticClass:"form__input",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.transactionpin.$error,
          },attrs:{"id":"transactionpin","name":"transactionpin","placeholder":"Choose OneBank Transaction Pin","autocorrect":"off","spellcheck":"false","autocomplete":"off","type":"radio"},domProps:{"checked":_vm._q(_vm.$v.form.transactionpin.$model,null)},on:{"change":function($event){return _vm.$set(_vm.$v.form.transactionpin, "$model", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.transactionpin.$model),expression:"$v.form.transactionpin.$model"}],staticClass:"form__input",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.transactionpin.$error,
          },attrs:{"id":"transactionpin","name":"transactionpin","placeholder":"Choose OneBank Transaction Pin","autocorrect":"off","spellcheck":"false","autocomplete":"off","type":_vm.passwordFieldTypeThree},domProps:{"value":(_vm.$v.form.transactionpin.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.form.transactionpin, "$model", $event.target.value)}}})]),_c('span',{staticClass:"form__password",on:{"click":_vm.switchVisibilityThree}},[_vm._v(_vm._s(_vm.passwordFieldTypeThree === "password" ? "Show" : "Hide"))]),(_vm.submitted && _vm.$v.form.transactionpin.$error)?_c('span',{staticClass:"error"},[(!_vm.$v.form.transactionpin.required)?_c('small',{staticClass:"error"},[_vm._v("Choose one bank transaction pin")]):_vm._e(),(!_vm.$v.form.transactionpin.numeric)?_c('small',{staticClass:"error"},[_vm._v("Needs to be numeric only.")]):_vm._e(),(!_vm.$v.form.transactionpin.maxLength || !_vm.$v.form.transactionpin.minLength)?_c('small',{staticClass:"error"},[_vm._v("Pin must be 4 numbers.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form__group"},[_c('label',{attrs:{"for":"confirm_onebank_transaction_pin"}},[((_vm.passwordFieldTypeFour)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.confirm_onebank_transaction_pin.$model),expression:"$v.form.confirm_onebank_transaction_pin.$model"}],staticClass:"form__input",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.confirm_onebank_transaction_pin.$error,
          },attrs:{"id":"confirm_onebank_transaction_pin","name":"confirm_onebank_transaction_pin","placeholder":"Confirm OneBank Transaction Pin","autocorrect":"off","spellcheck":"false","autocomplete":"off","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.$v.form.confirm_onebank_transaction_pin.$model)?_vm._i(_vm.$v.form.confirm_onebank_transaction_pin.$model,null)>-1:(_vm.$v.form.confirm_onebank_transaction_pin.$model)},on:{"change":function($event){var $$a=_vm.$v.form.confirm_onebank_transaction_pin.$model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.$v.form.confirm_onebank_transaction_pin, "$model", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.$v.form.confirm_onebank_transaction_pin, "$model", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.$v.form.confirm_onebank_transaction_pin, "$model", $$c)}}}}):((_vm.passwordFieldTypeFour)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.confirm_onebank_transaction_pin.$model),expression:"$v.form.confirm_onebank_transaction_pin.$model"}],staticClass:"form__input",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.confirm_onebank_transaction_pin.$error,
          },attrs:{"id":"confirm_onebank_transaction_pin","name":"confirm_onebank_transaction_pin","placeholder":"Confirm OneBank Transaction Pin","autocorrect":"off","spellcheck":"false","autocomplete":"off","type":"radio"},domProps:{"checked":_vm._q(_vm.$v.form.confirm_onebank_transaction_pin.$model,null)},on:{"change":function($event){return _vm.$set(_vm.$v.form.confirm_onebank_transaction_pin, "$model", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.confirm_onebank_transaction_pin.$model),expression:"$v.form.confirm_onebank_transaction_pin.$model"}],staticClass:"form__input",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.confirm_onebank_transaction_pin.$error,
          },attrs:{"id":"confirm_onebank_transaction_pin","name":"confirm_onebank_transaction_pin","placeholder":"Confirm OneBank Transaction Pin","autocorrect":"off","spellcheck":"false","autocomplete":"off","type":_vm.passwordFieldTypeFour},domProps:{"value":(_vm.$v.form.confirm_onebank_transaction_pin.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.form.confirm_onebank_transaction_pin, "$model", $event.target.value)}}})]),_c('span',{staticClass:"form__password",on:{"click":_vm.switchVisibilityFour}},[_vm._v(_vm._s(_vm.passwordFieldTypeFour === "password" ? "Show" : "Hide"))]),(_vm.submitted && _vm.$v.form.confirm_onebank_transaction_pin.$error)?_c('span',{staticClass:"error"},[(!_vm.$v.form.confirm_onebank_transaction_pin.required)?_c('small',{staticClass:"error"},[_vm._v("Please, confirm your OneBank transaction pin")]):_vm._e(),(!_vm.$v.form.confirm_onebank_transaction_pin.sameAsPin)?_c('small',{staticClass:"error"},[_vm._v("Pins do not match.")]):_vm._e(),(!_vm.$v.form.confirm_onebank_transaction_pin.numeric)?_c('small',{staticClass:"error"},[_vm._v("Needs to be numeric only.")]):_vm._e(),(!_vm.$v.form.transactionpin.maxLength || !_vm.$v.form.transactionpin.minLength)?_c('small',{staticClass:"error"},[_vm._v("Pin must be 4 numbers.")]):_vm._e()]):_vm._e()]),_c('button',{staticClass:"btn btn__red btn__group",attrs:{"type":"submit"}},[(_vm.loading)?_c('loading'):_vm._e(),_c('span',[_vm._v("Generate OTP")])],1)]),(_vm.showOtpModal)?_c('app-otp-modal',{ref:"otpInput",attrs:{"showOtpModal":_vm.showOtpModal},on:{"close":function($event){_vm.showOtpModal = false},"otPin":_vm.setOtpValue}}):_vm._e(),(_vm.showUsernameModal)?_c('username-modal',{ref:"usernameInput",attrs:{"showUsernameModal":_vm.showUsernameModal},on:{"close":function($event){_vm.showUsernameModal = false},"userModal":_vm.onboardOnePayUser}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"heading"},[_c('h2',[_vm._v("Sign up with your OnePay Account")]),_c('p',[_vm._v("Let's setup your account real quick!")])])}]

export { render, staticRenderFns }