<template>
  <div class="container" v-loading="loadingTwo">
    <a class="btn__back" @click="$router.go(-1)">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 20C15.5141 20 20 15.5141 20 10C20 4.48586 15.5141 0 10 0C4.48586 0 0 4.48586 0 10C0 15.5141 4.48586 20 10 20ZM6.21121 9.68795L11.4263 4.47283C11.5115 4.38763 11.6228 4.34591 11.734 4.34591C11.8453 4.34591 11.9565 4.38849 12.0417 4.47283C12.2112 4.64232 12.2112 4.91786 12.0417 5.08735L7.13429 9.99479L12.0409 14.9031C12.2104 15.0726 12.2104 15.3481 12.0409 15.5176C11.8714 15.6871 11.5958 15.6871 11.4263 15.5176L6.21121 10.3025C6.04172 10.133 6.04172 9.85744 6.21121 9.68795Z"
          fill="#DB353A"
        />
      </svg>
      <span>Back</span>
    </a>

    <div class="heading">
      <h2>Sign up with your OnePay Account</h2>
      <p>Let's setup your account real quick!</p>
    </div>

    <form class="form" @submit.prevent="submitForm" novalidate="true">
      <div class="form__group">
        <label for="username">
          <input
            type="text"
            id="username"
            name="username"
            placeholder="OnePay Username"
            class="form__input"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            v-model.lazy="$v.form.username.$model"
            :class="{
              'is-invalid': submitted && $v.form.username.$error,
            }"
        /></label>
        <small class="error" v-if="submitted && !$v.form.username.required"
          >Please, enter your OnePay username!</small
        >
      </div>
      <div class="form__group">
        <label for="password">
          <input
            type="password"
            id="password"
            name="password"
            placeholder="OnePay Password"
            class="form__input"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            v-model.lazy="$v.form.password.$model"
            :class="{
              'is-invalid': submitted && $v.form.password.$error,
            }"
        /></label>
        <small class="error" v-if="submitted && !$v.form.password.required"
          >Please enter your OnePay password</small
        >
      </div>
      <div class="form__group">
        <label for="transactionpin">
          <input
            id="transactionpin"
            :type="passwordFieldTypeThree"
            name="transactionpin"
            placeholder="Choose OneBank Transaction Pin"
            class="form__input"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            v-model="$v.form.transactionpin.$model"
            :class="{
              'is-invalid': submitted && $v.form.transactionpin.$error,
            }"
        /></label>
        <span @click="switchVisibilityThree" class="form__password">{{
          passwordFieldTypeThree === "password" ? "Show" : "Hide"
        }}</span>
        <span v-if="submitted && $v.form.transactionpin.$error" class="error">
          <small class="error" v-if="!$v.form.transactionpin.required"
            >Choose one bank transaction pin</small
          >
          <small class="error" v-if="!$v.form.transactionpin.numeric"
            >Needs to be numeric only.</small
          >
          <small
            class="error"
            v-if="!$v.form.transactionpin.maxLength || !$v.form.transactionpin.minLength"
            >Pin must be 4 numbers.</small
          ></span
        >
      </div>
      <div class="form__group">
        <label for="confirm_onebank_transaction_pin">
          <input
            :type="passwordFieldTypeFour"
            id="confirm_onebank_transaction_pin"
            name="confirm_onebank_transaction_pin"
            placeholder="Confirm OneBank Transaction Pin"
            class="form__input"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            v-model="$v.form.confirm_onebank_transaction_pin.$model"
            :class="{
              'is-invalid': submitted && $v.form.confirm_onebank_transaction_pin.$error,
            }"
        /></label>
        <span @click="switchVisibilityFour" class="form__password">{{
          passwordFieldTypeFour === "password" ? "Show" : "Hide"
        }}</span>
        <span
          v-if="submitted && $v.form.confirm_onebank_transaction_pin.$error"
          class="error"
        >
          <small class="error" v-if="!$v.form.confirm_onebank_transaction_pin.required"
            >Please, confirm your OneBank transaction pin</small
          >
          <small class="error" v-if="!$v.form.confirm_onebank_transaction_pin.sameAsPin"
            >Pins do not match.</small
          >
          <small class="error" v-if="!$v.form.confirm_onebank_transaction_pin.numeric"
            >Needs to be numeric only.</small
          >
          <small
            class="error"
            v-if="!$v.form.transactionpin.maxLength || !$v.form.transactionpin.minLength"
            >Pin must be 4 numbers.</small
          >
        </span>
      </div>
      <button type="submit" class="btn btn__red btn__group">
        <loading v-if="loading" />
        <span>Generate OTP</span>
      </button>
    </form>
    <app-otp-modal
      ref="otpInput"
      v-if="showOtpModal"
      :showOtpModal="showOtpModal"
      @close="showOtpModal = false"
      @otPin="setOtpValue"
    ></app-otp-modal>
    <username-modal
      ref="usernameInput"
      v-if="showUsernameModal"
      :showUsernameModal="showUsernameModal"
      @close="showUsernameModal = false"
      @userModal="onboardOnePayUser"
    ></username-modal>
  </div>
</template>

<script>
import OtpModal from "@/widgets/OtpModal.vue";
import UsernameModal from "@/widgets/UsernameModal.vue";
import api from "@/api/api.js";
import {
  required,
  sameAs,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
export default {
  components: {
    "app-otp-modal": OtpModal,
    "username-modal": UsernameModal,
  },
  data() {
    return {
      passwordFieldTypeThree: "password",
      passwordFieldTypeFour: "password",
      loading: false,
      loadingTwo: false,
      submitted: false,
      showOtpModal: false,
      showUsernameModal: false,
      otp: "",
      form: {
        username: "",
        password: "",
        transactionpin: "",
        confirm_onebank_transaction_pin: "",
      },
    };
  },
  validations: {
    form: {
      username: { required },
      password: {
        required,
      },
      transactionpin: {
        required,
        numeric,
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
      confirm_onebank_transaction_pin: {
        required,
        numeric,
        minLength: minLength(4),
        maxLength: maxLength(4),
        sameAsPin: sameAs("transactionpin"),
      },
    },
  },
  methods: {
    switchVisibilityThree() {
      this.passwordFieldTypeThree =
        this.passwordFieldTypeThree === "password" ? "text" : "password";
    },
    switchVisibilityFour() {
      this.passwordFieldTypeFour =
        this.passwordFieldTypeFour === "password" ? "text" : "password";
    },
    otpGeneration() {
      this.loading = true;
      let payload = {
        username: this.form.username,
        password: this.form.password,
      };
      api
        .onePayOtpGeneration(payload)
        .then((response) => {
          this.loading = false;
          let responseMessage = response.Message;
          let responseStatus = response.Status;
          if (responseStatus && responseMessage === "OTP Generated Successfully") {
            this.showOtpModal = true;
            this.$message({
              showClose: true,
              message: `OTP Generated Successfully`,
              type: "success",
              duration: 10000,
            });
          } else {
            this.$message({
              showClose: true,
              message: `${responseMessage}`,
              type: "warning",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `OTP generation error`,
            type: "error",
            duration: 10000,
          });
          throw error;
        });
    },
    onboardOnePayUser() {
      let payload = {
        username: this.form.username,
        password: this.form.password,
        transactionpin: this.form.transactionpin,
        appversion: 1,
        deviceimei: "imei",
        deviceos: "web",
        devicemake: "web",
        devicemodel: "web",
        otp: this.otp,
        hardwareIMEI: "web",
      };
      this.loadingTwo = true;

      api
        .onboardOnePayUser(payload)
        .then((response) => {
          this.loadingTwo = false;
          let responseCode = response.Code;
          let responseStatus = response.Status;
          let responseMessage = response.Message;
          if (responseStatus && responseCode == "CUSCREATE002") {
            this.$message({
              showClose: true,
              message: `User onboarded successfully, proceed to login`,
              type: "success",
              duration: 10000,
            });
            this.$router.push("/login");
          } else if (responseStatus && responseCode == "USEREXIST001") {
            this.showUsernameModal = true;
            this.$store.commit("setOnePayNewUsernameData", payload);
          } else {
            this.$message({
              showClose: true,
              message: `${responseMessage}`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.loadingTwo = false;
          this.$message({
            showClose: true,
            message: `Error onboarding user`,
            type: "error",
            duration: 10000,
          });
          throw error;
        });
    },
    submitForm() {
      this.submitted = true;

      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return;
      }
      this.otpGeneration();
    },
    setOtpValue(value) {
      this.showOtpModal = false;
      this.otp = value;
      this.onboardOnePayUser();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
</style>
